import { Routes } from '@angular/router';
import { authGuard } from './auth/auth.guard';
import { authRoutes } from './auth/auth.routes';
import { publicGuard } from './auth/public.guard';
import { SimpleLayoutComponent } from './layout/simple-layout/simple-layout.component';

const adminUIRoutes: Routes = [
  {
    path: '',
    redirectTo: 'registry-users',
    pathMatch: 'full',
  },
  {
    path: 'registry-users',
    loadChildren: () => import('./registry-users/registry.users.routes').then(routes => routes.registryUsersRoutes),
  },
  {
    path: 'package-groups',
    loadChildren: () => import('./package-groups/package-groups.routes').then(routes => routes.packageGroupsRoutes),
  },
  {
    path: 'package-access-control',
    loadChildren: () => import('./package-access-control/package-access-control.routes').then(routes => routes.packageAccessControlRoutes),
  },
  {
    path: 'admins',
    loadChildren: () => import('./admin/admin.routes').then(routes => routes.adminRoutes),
  },
  {
    path: 'roles',
    loadChildren: () => import('./roles/roles.routes').then(routes => routes.rolesRoutes),
  },
];

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [authGuard],
    loadComponent: () => import('./layout/full-layout/full-layout.component').then(c => c.FullLayoutComponent),
    children: adminUIRoutes,
  },
  {
    path: 'auth',
    canActivate: [publicGuard],
    component: SimpleLayoutComponent,
    children: authRoutes,
  },
];
